import { IProduct, Product } from "./Product";

export interface IProductDetails extends IProduct {
  readme: string;
  supportedTags: string[];
  projectWebsite: string;
  projectRepostioryUrl: string;
  licenseType: string;
  licenseUrl: string;
  supportLink: string;
  documentationLink: string;
  totalPullCount: string;
  sourceRepository: string;
  readmeNeedsTagTable: boolean;
}

export class ProductDetails extends Product {

  public readonly readme: string;
  public readonly supportedTags: string[];
  public readonly projectWebsite: string | null;
  public readonly projectRepostioryUrl: string | null;
  public readonly licenseType: string;
  public readonly licenseUrl: string | null;
  public readonly supportLink: string | null;
  public readonly documentationLink: string | null;
  public readonly totalPullCount: string;
  public readonly sourceRepository: string;
  public readonly readmeNeedsTagTable: boolean;

  private constructor(productData: IProductDetails) {
    super(productData);

    this.readme = productData.readme;
    this.supportedTags = productData.supportedTags;
    this.projectWebsite = productData.projectWebsite;
    this.projectRepostioryUrl = productData.projectRepostioryUrl;
    this.licenseType = productData.licenseType;
    this.licenseUrl = productData.licenseUrl;
    this.supportLink = productData.supportLink;
    this.documentationLink = productData.documentationLink;
    this.totalPullCount = productData.totalPullCount;
    this.sourceRepository = productData.sourceRepository;
    this.readmeNeedsTagTable = productData.readmeNeedsTagTable;
  }

  public static fromJsonData(productData: IProductDetails) {
    return new ProductDetails(productData);
  }
}