import { ProductFilter } from "@components/Pages/Catalog/Page/Catalog";
import { HTTPError } from "@errors/HttpError";
import { IProduct, Product } from "@models/Product";
import { IProductDetails, ProductDetails } from "@models/ProductDetails";
import { Tag, ITag } from "@models/Tag";
import { HttpStatusCode } from "@utils/Http/HttpStatusCode";

export namespace CatalogAPI {

  const API_URL = "/api/v1/catalog";

  export async function getProducts(queryStr: string) {
    
    const url = `${API_URL}/products${queryStr}`;
    let response = await fetch(url, {
      method: "GET"
    });
    if (response.ok) {
      let data: IProduct[] = await response.json();
      return data.map(Product.fromJsonData);
    } else {
      throw new HTTPError(response.status, "Failed to retreive products, please try again later...");
    }
  }

  export async function getProductDetails(registry: string, repo: string) {
    if (repo.endsWith("/")) {
      repo = repo.substr(0, repo.length - 1);
    }
    const url = `${API_URL}/${repo}/details?reg=${registry}`;
    let response = await fetch(url, {
      method: "GET"
    });
    if (response.ok) {
      let data: IProductDetails = await response.json();
      return ProductDetails.fromJsonData(data);
    } else if (response.status === HttpStatusCode.NotFound) {
      throw new HTTPError(response.status, "No such repository by the name: " + repo);
    } else {
      throw new HTTPError(response.status, "Failed to retrieve product details, please try again later...");
    }
  }

  export async function getAllProductCategories() {

    const url = `${API_URL}/products/categories`;
    let response = await fetch(url, {
      method: "GET"
    });
    if (response.ok) {
      let data: string[] = await response.json();
      return data;
    } else if (response.status === HttpStatusCode.NotFound) {
      throw new HTTPError(response.status, "No categories found: ");
    } else {
      throw new HTTPError(response.status, "Failed to retreive product categories, please try again later...");
    }
  }

  export async function getArtifactData(regHash: string, repo: string, digest: string) {
    if (repo.endsWith("/")) {
      repo = repo.substr(0, repo.length - 1);
    }
    const url = `${API_URL}/artifact/?regHash=${regHash}&repo=${repo}&sha=${digest}`;
    let response = await fetch(url, {
      method: "GET"
    });
    return response;
    
  }

  export async function getProductTags(regHash: string, repo: string) {
    if (repo.endsWith("/")) {
      repo = repo.substr(0, repo.length - 1);
    }
    const url = `${API_URL}/${repo}/tags?reg=${regHash}`;
    let response = await fetch(url, {
      method: "GET"
    });
    if (response.status === HttpStatusCode.OK) {
      let data: ITag[] = await response.json();
      return data.map(Tag.fromJsonData);
    } else if (response.status === HttpStatusCode.Conflict) {
      throw new HTTPError(response.status, "We are currently compiling the tag list for this repository, please try again later...");
    } else if (response.status === HttpStatusCode.NotFound) {
      throw new HTTPError(response.status, "No such repository by the name: " + repo);
    } else {
      throw new HTTPError(response.status, "Failed to retreive product details, please try again later...");
    }
  }

  export async function getTagTable(registry: string, sourceRepo: string) {
    if (sourceRepo.endsWith("/")) {
      sourceRepo = sourceRepo.substr(0, sourceRepo.length - 1);
    }
    const url = `${API_URL}/${sourceRepo}/tagtable?reg=${registry}`;
    let response = await fetch(url, {
      method: "GET"
    });
    if (response.ok) {
      let data: string = await response.text();
      return data;
    } else if (response.status === HttpStatusCode.NotFound) {
      throw new HTTPError(response.status, "No tag table found for repository: " + sourceRepo);
    } else {
      throw new HTTPError(response.status, "Failed to retrieve tag table, please try again later...");
    }
  }
}

